.l-text-base {
  /* text-base */
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.l-text-base-medium {
  /* text-base-medium */
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.l-text-xs {
  /* text-xs */
  font-family: Space Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.l-text-sm {
  /* text-sm */
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.l-text-xl-medium {
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.l-text-lg {
  /* text-lg */
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.l-text-lg-medium {
  /* text-lg-medium */
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.l-text-2xl-semibold {
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.l-text-3xl-semibold {
  /* text-3xl-semibold */
  font-family: Space Grotesk;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 120% */
}

.l-text-4xl-semibold {
  /* text-4xl-semibold */
  font-family: Space Grotesk;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 111.111% */
}

.l-text-48 {
  font-family: Space Grotesk;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.l-text-100 {
  font-family: Space Grotesk;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -3px;
}

.l-text-title {
  font-family: Iceland;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -4.2px;
}

@keyframes rotateToLeft {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}

@keyframes rotateToRight {
  0% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

.paused {
  animation-play-state: paused !important;
}

/* Hide the stepper */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
